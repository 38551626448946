$amarillo: #ffb703;
$azul-1: #ecf6f7;
$azul-2: #8ecae6;
$azul-3: #219ebc;
$azul-4: #023047;
$blanco: #ffffff;
$borde-gris: #d1d1d1;
$gris-1: #d1d1d1;
$gris-2: #575757;
$gris-3: #f5f5fc;
$placeholder: #b5b7cb;
$naranja: #ff7a00;
$rojo: #dc392f;
$texto-gris: #575757;
$verde: #19b875;
$input-borde: #e4e6ef;
$lila: #ef5da8;
$lila-2: #420022;
$negro-1: #49494b;
$violeta1: #5200ff;
$violeta2: #1c0058;
$naranja-claro: #fdf3e4;
$verde-claro: #d9fdee;
$oscuro: #2b2b2b;

$primary: #3699ff !default; // Bootstrap variable

.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 90vh;
}

#app {
  .next-steps .fa-link {
    margin-right: 5px;
  }

  /* Fix for use only flexbox in content area */
  .next-steps .row {
    margin-bottom: 0;
  }

  .next-steps .col-md-5 {
    margin-bottom: 3rem;
  }

  @media (max-width: 768px) {
    .next-steps .col-md-5 {
      margin-bottom: 0;
    }
  }

  .map-container {
    height: 300px !important;
    width: 100% !important;
  }

  .spinner {
    position: absolute;
    display: flex;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background-color: white;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .result-block-container .result-block {
    opacity: 1;
  }

  .w-33 {
    width: 33% !important;
  }

  .w-80 {
    width: 80% !important;
  }

  div {
    &::-webkit-scrollbar {
      background-color: $borde-gris;
      height: 3px;
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $azul-4;
      border-radius: 10px;
      box-shadow: inset 0 0 6px $lila-2;
    }
  }

  .no-header .MuiDataGrid-columnHeaders {
    display: none;
  }

  .nav-container .navbar {
    position: sticky !important;
    left: 0 !important;
    top: 0 !important;
    padding-right: 0px !important;
  }

  .listado-conjunto-container {
    border: 2px solid $gris-1;
    border-radius: 20px;
    padding: 10px !important;
    text-align: center;
    margin-bottom: 15px;

    .item {
      margin: 2px;
      background-color: $gris-1;
      border-radius: 5px;
    }
  }

  .h-45 {
    height: 45% !important;
  }

  .datepicker {
    width: 250px;
    cursor: pointer !important;
  }

  .programacion-tablado-card {
    border: 1px solid $gris-1;
    border-radius: 20px;
    padding: 10px !important;
    text-align: center;
    margin: 5px;
    min-width: 300px !important;

    .item {
      margin: 2px;
      background-color: $gris-1;
      border-radius: 5px;
    }
  }

  [data-quantity] {
    position: relative;
    width: 130px;
    max-width: 130px;
    padding: 0;
    margin: 0;
    border: 0;
  }

  [data-quantity] legend {
    display: none;
  }

  [data-quantity] input {
    font-size: 18px;
    height: 2rem;
    padding: 0 3rem;
    border-radius: 1rem;
    border: 0;
    background: #ebebeb;
    color: #222;
    box-shadow: 0 10px 65px -10px rgba(0, 0, 0, 0.25);
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    font-weight: lighter;
  }

  [data-quantity] input:focus {
    outline: none;
    box-shadow: 0 5px 55px -10px rgba(0, 0, 0, 0.2), 0 0 4px #3fb0ff;
    /* Allows border radius on focus */
  }

  [data-quantity] input[type="number"]::-webkit-inner-spin-button,
  [data-quantity] input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  [data-quantity] input[type="number"] {
    -moz-appearance: textfield;
    font-weight: 450;
  }

  [data-quantity] button {
    position: absolute;
    width: 2rem;
    height: 1.5rem;
    top: 0.2rem !important;

    display: block;
    padding: 0;
    border: 0;
    background: #ebebeb url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="50"><path d="M17 25h16M67 25h16M75 17v16" fill="none" stroke="rgb(59,69,66)" stroke-width="1.5px" stroke-linecap="round" stroke-linejoin="round" /></svg>') no-repeat 0 0;
    background-size: 4.6rem 1.8rem;
    overflow: hidden;
    white-space: nowrap;
    text-indent: 100%;
    border-radius: 1.4rem;
    cursor: pointer;
    transition: opacity 0.15s;
    opacity: 0.5;
  }

  [data-quantity] button:active {
    background-position-y: 1px;
    box-shadow: inset 0 2px 12px -4px #c5d1d9;
  }

  [data-quantity] button:focus {
    outline: none;
  }

  [data-quantity] button:hover {
    opacity: 1;
  }

  [data-quantity] button.sub {
    left: 0.6rem;
    top: -0.4rem;
    background-position-x: -0.1rem;
  }

  [data-quantity] button.add {
    right: 0.7rem;
    top: -0.4rem;
    background-position-x: -2.4rem;
  }

  .file-upload {
    border-radius: 10px;
    padding: 10px;
    vertical-align: middle;
    margin: 10px;
    width: 95%;
    min-height: 300px;
    background-color: $gris-1;

    label {
      margin: 0;
    }
  }

  .img-preview {
    text-align: center !important;
    max-width: 90%;
    max-height: 220px;
    margin-top: 10px;
  }

  .action-icon {
    cursor: pointer;
    opacity: 0.8;
  }

  .action-icon:hover {
    opacity: 1;
  }

  .google-maps-loading {
    background-image: url("./assets/loadingGoogleMaps.gif");
    width: 280px;
    height: 280px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .top-line-blue {
    position: relative !important;
    bottom: -25px !important;
    z-index: 1 !important;
  }

  .bottom-line-blue {
    position: relative !important;
    bottom: 25px !important;
  }

  .pronostico .item {
    // cursor: move;
  }

  // .draggable-item :hover {
  //   opacity: 0.9;
  //   background-color: #2279d5;
  //   color: $blanco;
  //   input {
  //     background-color: $blanco;
  //   }
  // }

  .draggable-item .item {

    //cursor: move;
    input {
      background-color: $blanco;
    }
  }

  .toggle-icon-open {
    position: relative;
    left: 35px;
    bottom: 10px;
    opacity: 1;
  }

  .toggle-icon-close {
    position: relative;
    left: 35px;
    bottom: 10px;
    opacity: 0.5;
  }

  .toggle-switch-with-icon {
    position: relative;
    top: 10px;
  }

  .container {
    max-width: 1400px !important;
  }

  .separador-liguilla {
    border-top: 2px dashed $rojo !important;
  }

  .opaco {
    opacity: 0.6 !important;
  }

  @media (min-width: 992px) {
    .navbar {
      margin: 0;
      width: 10%;
      height: 100vh;
    }

    form input {
      margin-bottom: 0.7em;
    }
  }

  .nav-container {
    background-color: #5226cba9 !important;
  }

  /* Cambiar el estilo de la barra de navegación */
  .navbar {
    background-color: #5226cba9 !important;
    width: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    height: 100vh !important;
    /* Ajusta la altura según tus necesidades */
  }

  /* Cambiar el estilo de los elementos del menú */
  .nav-item {
    width: 100% !important;
    text-align: left !important;
  }

  /* Ajustar los márgenes de los elementos */
  .nav-item .nav-link {
    margin-left: 1rem;
  }

  li a {
    width: 100% !important;
    padding: 10px !important;
    text-align: center !important;
    margin: 5px !important;
    color: white !important;
    border: 0 !important;
    border-radius: 20px !important;
  }

  li a.active {
    background-color: #ffffff !important;
    color: black !important;
    border-radius: 20px !important;
    box-shadow: 1px -1px 21px 11px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 1px -1px 21px 11px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 1px -1px 21px 11px rgba(0, 0, 0, 0.4);
  }

  .perfilmenu {
    position: absolute !important;
    top: 60% !important;
    left: 30px !important;
  }

  .informacion-carga {
    margin-top: 20px;
  }

  .titulo-carga {
    font-size: 1.5em;
  }

  .lista-carga {
    list-style: none;
    padding: 0;
  }

  .negrita {
    font-weight: bold;
  }

  .novedades-lista {
    list-style: none;
    padding: 0;
    margin-top: 5px;
  }
}

.enviar-pedido {
  border-radius: 16px;
  background: var(--plano-green, #07ca9b);
  width: 180px;
  font-weight: 700;
  font-family: "MuseoSans";
  margin-top: 50px;
}