@font-face {
  font-family: "MuseoSans";
  src: url("../assets/MuseoSans_500.otf") format("woff2");
}

#landing-website {
  .conjunto-clasificado {
    border-radius: 12px;
    border: 1px solid white;

    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
    padding: 4px;
    color: white;
    font-weight: 600;
  }

  .conjunto-eliminado {
    border-radius: 12px;
    border: 1px solid rgb(61, 60, 60);
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
    padding: 4px;
    background-color: rgb(61, 60, 60);
    color: rgba(255, 255, 255, 0.781);
    font-weight: 400;
  }

  padding-top: 62px;
  min-height: 100vh;
  justify-content: center !important;
  background: linear-gradient(180deg, #cc34cf 0%, #3446a4 100%) !important;
  background-attachment: fixed !important;

  .logo-carnavalapp {
    background-image: url("../assets/logo.svg");
    width: 520px;
    height: 122px;
    margin-left: auto;
    margin-right: auto;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    left: 50px;
  }

  .logo-carnavalapp-eslogan {
    background-image: url("../assets/logo-slogan.svg");
    width: 332px;
    height: 41px;
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    left: 50px;
  }

  .navaja-y-texto {
    width: 100vw;
    height: 600px;
    padding-left: 0;
    margin-bottom: 100px;
    display: flex;
    justify-content: center;
  }

  .img-navaja {
    background-image: url("../assets/navaja.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 1110px;
    height: 792px;
    position: relative;
    left: 10vw;
  }

  .texto-mobile,
  .img-navaja-mobile {
    display: none !important;
  }

  .texto {
    padding-top: 20vh;
    padding-right: 14vw !important;
  }

  .texto .texto-titulo {
    color: white;
    font-size: 30px;
    font-family: "MuseoSans";
    font-weight: 700;
    line-height: 46px;
  }

  .texto .texto-descripcion {
    color: white;
    font-size: 22px;
    font-family: "MuseoSans";
    font-weight: 400;
    line-height: 38px;
    word-wrap: break-word;
    margin-top: 37px;
  }

  .video-container {
    display: flex;
    width: 100vw;
    text-align: center;
    justify-content: center;
    padding-bottom: 185px;
  }

  .play-icon {
    position: absolute;
  }

  .call-to-action {
    color: white;
    font-size: 48px;
    font-family: "MuseoSans";
    font-weight: 800;
    line-height: 46px;
    width: 100vw;
    justify-content: center;
    text-align: center;
  }

  .tiendas {
    margin-top: 80px;
    justify-content: center;
    width: 100vw;
    padding-left: 2vw !important;
  }

  .google-play {
    background-position: right;
    background-image: url("../assets/google-play.svg");
    background-size: contain;
    background-repeat: no-repeat;
    height: 117px;
    margin-top: -18px;
    margin-right: 30px;
    cursor: pointer;
  }

  .app-store {
    background-position: left;
    background-image: url("../assets/app-store.svg");
    background-size: contain;
    background-repeat: no-repeat;
    height: 80px;
    margin-left: 30px;
    cursor: pointer;
  }

  .hablemos {
    margin-top: 150px;
    justify-content: center;
    padding-bottom: 130px;
  }

  .icono-mascara {
    display: block;
    margin: auto;
    background-image: url("../assets/mascara-icono.svg");
    background-size: contain;
    background-repeat: no-repeat;
    height: 47px;
    width: 78px;
    margin-bottom: 32px;
  }

  .hablemos-titulo {
    display: block;
    margin: auto;
    justify-content: center;
    text-align: center;
    color: white;
    font-size: 48px;
    font-family: "MuseoSans";
    font-weight: 700;
    line-height: 46px;
    word-wrap: break-word;
  }

  .hablemos-texto {
    margin-top: 42px !important;
    color: white;
    font-size: 24px;
    font-family: "MuseoSans";
    font-weight: 400 !important;
    line-height: 34px;
    word-wrap: break-word;
    width: 660px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .hablemos-mail {
    margin-top: 42px !important;
    color: white;
    font-size: 24px;
    font-family: "MuseoSans";
    font-weight: 600;
    line-height: 34px;
    word-wrap: break-word;
    width: 660px !important;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    justify-content: center;
  }

  .mail-icon {
    background-image: url("../assets/mail.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 32px;
    height: 32px;
  }

  a.hablemos-mail:hover,
  .hablemos-mail:hover {
    color: white !important;
    scale: 1.1 !important;
    text-decoration: solid !important;
  }

  .video-player {
    width: 1140px !important;
    height: 643px !important;
  }

  .preview-video {
    width: 1140px !important;
    height: 643px !important;
  }

  @media only screen and (max-width: 1024px) {
    .texto {
      padding-top: 10vh;
    }
    .texto-titulo {
      color: white;
      font-size: 24px !important;
      font-family: "MuseoSans";
      font-weight: 700 !important;
      line-height: 46px !important;
      margin-top: 8px !important;
      margin-bottom: 24px !important;
    }

    .img-navaja {
      scale: 1.3;
      left: 7vw !important;
      top: 15vh !important;
    }

    .texto {
      padding-right: 6vw !important;
      padding-left: 4vw !important;
    }

    .texto-descripcion {
      color: white;
      font-size: 16px;
      font-family: "MuseoSans";
      font-weight: 400 !important;
      line-height: 24px;
      word-wrap: break-word;
    }

    .navaja-y-texto {
      margin-bottom: 0px !important;
    }
    .hablemos {
      margin-top: 70px !important;
      justify-content: center;
      padding-bottom: 100px !important;
    }

    .tiendas {
      margin-top: 60px !important;
    }
  }

  @media only screen and (max-width: 480px) {
    .logo-carnavalapp {
      background-image: url("../assets/logo.svg");
      width: 280px !important;
      height: 65px !important;
      left: 10px !important;
    }

    .logo-carnavalapp-eslogan {
      background-image: url("../assets/logo-slogan.svg");
      width: 140px;
      left: 0 !important;
    }

    .navaja-y-texto {
      display: none !important;
    }

    .img-navaja-mobile {
      scale: 1;
      display: block !important;
      background-image: url("../assets/navaja_mobile.png");
      background-size: cover !important;
      background-repeat: no-repeat;
      background-position-x: -40vw !important;
      width: 100vw;
      height: 120vw;
      position: relative;
      top: -7vh !important;
    }

    .texto-mobile {
      margin: auto;
      justify-content: center;
      text-align: center;
      display: block !important;
      width: 90vw !important;
      margin-top: -110px !important;
      margin-bottom: 50px !important;
      .texto-titulo {
        color: white;
        font-size: 30px;
        font-family: "MuseoSans";
      }
    }

    .hablemos-titulo {
      font-size: 28px !important;
      font-weight: 700 !important;
      line-height: 46px !important;
    }

    .hablemos-texto {
      font-size: 16px !important;
      font-weight: normal !important;
      line-height: 22px !important;
      word-wrap: break-word !important;
      margin-top: 24px !important;
      text-align: center !important;
      width: 80vw !important;
    }

    .hablemos-mail {
      width: 80vw !important;
    }

    .video-container {
      display: flex;
      width: 100vw !important;
      height: 56vw !important;
      text-align: center;
      justify-content: center;
      padding-bottom: 185px;
    }

    .call-to-action {
      margin-top: 50px !important;
      color: white;
      font-size: 16px;
      font-family: "MuseoSans";
      font-weight: 700;
      line-height: 20.26px;
      word-wrap: break-word;
    }

    .video-player {
      width: 100vw !important;
      height: 56vw !important;
    }

    .preview-video {
      width: 100vw !important;
      height: 56vw !important;
    }

    .google-play {
      background-position: right;
      background-image: url("../assets/google-play.svg");
      background-size: contain;
      background-repeat: no-repeat;
      height: 117px;
      margin-top: -18px;
      cursor: pointer;
      margin-right: 0 !important;
    }

    .app-store {
      background-position: left;
      background-image: url("../assets/app-store.svg");
      background-size: contain;
      background-repeat: no-repeat;
      margin-top: 17px !important;
      height: 48px !important;
      cursor: pointer;
      margin-left: 0px !important;
    }

    .tiendas {
      margin-top: 12px !important;
      padding-left: 12vw !important;
    }

    .hablemos {
      margin-top: 36px !important;
    }

    .hablemos-texto {
      font-size: 20px;
      font-family: "MuseoSans";
      font-weight: 400;
      line-height: 34px;
    }

    .hablemos-mail {
      font-size: 20px;
      font-family: "MuseoSans";
      font-weight: 400;
    }

    .MuiSnackbar-root {
      position: fixed !important;
      top: 12px !important;
      left: 4px !important;
    }
  }
}
