@font-face {
  font-family: "MuseoSans";
  src: url("../assets/MuseoSans_500.otf") format("woff2");
}

#calculadora-page {
  justify-content: center !important;
  background: var(--sec-gradient2,
      linear-gradient(180deg, #29aef9 0%, #3446a4 100%)) !important;
  background-attachment: fixed !important;
  color: white !important;
  min-height: 100vh !important;

  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;

  .img-anunciantes {
    width: 100vw !important;
  }

  .container {
    max-width: 1200px !important;
    min-height: 600px !important;
    justify-content: center !important;
  }

  h5 {
    color: var(--plano-white, #fff);
    text-align: center;
    font-family: "MuseoSans";
    font-size: 20px;
    font-style: normal;
    line-height: 30px;
    font-weight: 400;
  }

  .calculadora-select {
    background-color: #fff !important;
    width: 200px;
  }

  .label,
  .label h5 {
    margin-right: 20px;
    width: 200px !important;
    justify-content: end !important;
    text-align: right !important;
  }

  .logo-carnavalapp {
    background-image: url("../assets/logo.svg");
    width: 373px;
    height: 120px;
    margin-left: auto;
    margin-right: auto;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .probalo {
    display: block;
    text-align: center;
    padding: 8px 14px 8px 14px;
    background: #f112e8;
    // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 32px;
    color: white;
    font-size: 20px;
    font-family: "MuseoSans";
    font-weight: 600;
    line-height: 34px;
    word-wrap: break-word;
  }

  .label-precio {
    color: white;
    font-size: 36px;
    font-family: "MuseoSans";
    font-weight: 700 !important;
    line-height: 46px;
    word-wrap: break-word;
  }

  .logo-carnavalapp-eslogan {
    background-image: url("../assets/logo-slogan.svg");
    width: 332px;
    height: 41px;
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .navaja-y-texto {
    width: 100vw;
    height: 600px;
    padding-left: 0;
    margin-bottom: 100px;
    display: flex;
    justify-content: center;
  }

  .img-navaja {
    background-image: url("../assets/navaja.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 1110px;
    height: 792px;
    position: relative;
    left: 10vw;
  }

  .texto-mobile,
  .img-navaja-mobile {
    display: none !important;
  }

  .texto {
    padding-top: 20vh;
    padding-right: 14vw !important;
  }

  .texto .texto-titulo {
    color: white;
    font-size: 30px;
    font-family: "MuseoSans";
    font-weight: 700;
    line-height: 46px;
  }

  .texto .texto-descripcion {
    color: white;
    font-size: 22px;
    font-family: "MuseoSans";
    font-weight: 400;
    line-height: 38px;
    word-wrap: break-word;
    margin-top: 37px;
  }

  .video-container {
    position: relative !important;
    top: -20vh;
    right: -55vw;
    width: 10vw;
    text-align: center;
    justify-content: center;
  }

  .play-icon {
    position: absolute;
  }

  .call-to-action {
    color: white;
    font-size: 48px;
    font-family: "MuseoSans";
    font-weight: 800;
    line-height: 46px;
    width: 100vw;
    justify-content: center;
    text-align: center;
  }

  .tiendas {
    margin-top: 80px;
    justify-content: center;
    width: 100vw;
    padding-left: 2vw !important;
  }

  .google-play {
    background-position: right;
    background-image: url("../assets/google-play.svg");
    background-size: contain;
    background-repeat: no-repeat;
    height: 117px;
    margin-top: -18px;
    margin-right: 30px;
    cursor: pointer;
  }

  .app-store {
    background-position: left;
    background-image: url("../assets/app-store.svg");
    background-size: contain;
    background-repeat: no-repeat;
    height: 80px;
    margin-left: 30px;
    cursor: pointer;
  }

  .hablemos {
    justify-content: center;
    padding-bottom: 130px;
  }

  .total {
    border: 1px solid white;
    border-radius: 15px;
    padding: 20px;
  }

  .icono-mascara {
    display: block;
    margin: auto;
    background-image: url("../assets/mascara-icono.svg");
    background-size: contain;
    background-repeat: no-repeat;
    height: 47px;
    width: 78px;
  }

  .hablemos-titulo {
    display: block;
    margin: auto;
    justify-content: center;
    text-align: center;
    color: var(--plano-white, #fff);
    text-align: center;
    font-family: "MuseoSans";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
  }

  .hablemos-texto {
    margin-top: 42px !important;
    color: var(--plano-white, #fff);
    text-align: center;
    font-family: "MuseoSans";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    /* 150% */
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .enviar-pedido {
    border-radius: 16px;
    background: var(--plano-green, #07ca9b);
    font-weight: 700;
    font-size: 20px;
    font-family: "MuseoSans";
    margin-top: 50px;
    width: auto;
  }

  .hablemos-texto-2 {
    color: var(--plano-white, #fff);
    font-family: "MuseoSans";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 46px;
    /* 191.667% */
  }

  .hablemos-mail {
    margin-top: 42px !important;
    color: white;
    font-size: 24px;
    font-family: "MuseoSans";
    font-weight: 600;
    line-height: 34px;
    word-wrap: break-word;
    width: 660px !important;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    justify-content: center;
  }

  .mail-icon {
    background-image: url("../assets/mail.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 32px;
    height: 32px;
  }

  .phone-icon {
    background-image: url("../assets/phone_android.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 32px;
    height: 32px;
  }

  a.hablemos-mail:hover,
  .hablemos-mail:hover {
    color: white !important;
    scale: 1.1 !important;
    text-decoration: solid !important;
  }

  .video-player {
    width: 40vw !important;
    height: 22vw !important;
  }

  .preview-video {
    width: 40vw !important;
    height: 22vw !important;
  }

  .banners {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 16px;
    flex-wrap: wrap;

    .banner-example {
      width: 30%;
      height: auto;
      position: relative;

      .banner-image {
        width: 100%;
        height: 500px;
        background-size: contain;
        /* Ajusta la imagen para cubrir el div */
        background-position: center;
        background-repeat: no-repeat;

        &.banner-standard {
          background-image: url('/assets/banner-estandar.svg');
          /* Ajusta la ruta */
        }

        &.banner-destacado {
          background-image: url('/assets/banner-destacado.svg');
          /* Ajusta la ruta */
        }

        &.banner-cabecera {
          background-image: url('/assets/banner-cabecera.svg');
          /* Ajusta la ruta */
        }
      }

      .banner-info {
        padding: 1rem;
      }
    }

    /* Estilos para pantallas pequeñas */
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      gap: 8px;

      .banner-example {
        width: 80%;
        /* Las imágenes ocupan el 80% del ancho */
        margin-bottom: 1rem;
        /* Mantiene la proporción correcta */
      }

    }
  }

  @media (max-width: 768px) {

    .hablemos-container {
      margin-left: 2rem !important;
      margin-right: 2rem !important;
    }
  }



  @media only screen and (max-width: 1024px) {
    .texto {
      padding-top: 10vh;
    }

    .texto-titulo {
      color: white;
      font-size: 24px !important;
      font-family: "MuseoSans";
      font-weight: 700 !important;
      line-height: 46px !important;
      margin-top: 8px !important;
      margin-bottom: 24px !important;
    }

    .img-navaja {
      scale: 1.3;
      left: 7vw !important;
      top: 15vh !important;
    }

    .texto {
      padding-right: 6vw !important;
      padding-left: 4vw !important;
    }

    .texto-descripcion {
      color: white;
      font-size: 16px;
      font-family: "MuseoSans";
      font-weight: 400 !important;
      line-height: 24px;
      word-wrap: break-word;
    }

    .navaja-y-texto {
      margin-bottom: 0px !important;
    }

    .hablemos {
      margin-top: 0px !important;
      justify-content: center;
      padding-bottom: 100px !important;
    }

    .tiendas {
      margin-top: 60px !important;
    }
  }

  @media only screen and (max-width: 480px) {
    .logo-carnavalapp {
      background-image: url("../assets/logo.svg");
      width: 280px !important;
      height: 65px !important;
      left: 10px !important;
    }

    .separador-precio {
      display: none !important;
    }

    .logo-carnavalapp-eslogan {
      background-image: url("../assets/logo-slogan.svg");
      width: 140px;
      left: 0 !important;
    }

    .navaja-y-texto {
      display: none !important;
    }

    .img-navaja-mobile {
      scale: 1;
      display: block !important;
      background-image: url("../assets/navaja_mobile.png");
      background-size: cover !important;
      background-repeat: no-repeat;
      background-position-x: -40vw !important;
      width: 100vw;
      height: 120vw;
      position: relative;
      top: -7vh !important;
    }

    .texto-mobile {
      margin: auto;
      justify-content: center;
      text-align: center;
      display: block !important;
      width: 90vw !important;
      margin-top: -110px !important;
      margin-bottom: 50px !important;

      .texto-titulo {
        color: white;
        font-size: 30px;
        font-family: "MuseoSans";
      }
    }

    .hablemos-titulo {
      font-size: 28px !important;
      font-weight: 700 !important;
      line-height: 46px !important;
    }

    .hablemos-texto {
      font-size: 16px !important;
      font-weight: normal !important;
      line-height: 22px !important;
      word-wrap: break-word !important;
      margin-top: 24px !important;
      text-align: center !important;
      width: 80vw !important;
    }

    .hablemos-mail {
      width: 80vw !important;
    }

    .call-to-action {
      margin-top: 50px !important;
      color: white;
      font-size: 16px;
      font-family: "MuseoSans";
      font-weight: 700;
      line-height: 20.26px;
      word-wrap: break-word;
    }

    .video-container {
      position: relative !important;
      top: -2vh;
      right: -5vw;
      width: 10vw;
      text-align: center;
      justify-content: center;
    }

    .play-icon {
      position: absolute;
      width: 100%;
    }

    .preview-video,
    .video-player {
      width: 90vw !important;
      height: 49vw !important;
    }

    .h3,
    .h5 {
      font-size: smaller !important;
    }

    .hablemos {
      margin-top: 36px !important;
      padding-bottom: 50px !important;
    }

    .hablemos-texto {
      font-size: 20px;
      font-family: "MuseoSans";
      font-weight: 400;
      line-height: 34px;
    }

    .hablemos-mail {
      font-size: 20px;
      font-family: "MuseoSans";
      font-weight: 400;
    }

    .MuiSnackbar-root {
      position: fixed !important;
      top: 12px !important;
      left: 4px !important;
    }

    .calculadora-select,
    .label {
      margin-bottom: 20px;
      text-align: center !important;
      width: 100% !important;
      width: 60vw !important;

      span.MuiSlider-root {
        width: 60vw !important;
      }

      h5 {
        text-align: center !important;
        width: 100% !important;
        position: static !important;
      }
    }

    div.ml-3 {
      margin-left: 0px !important;
    }

    .slider {
      width: 60vw !important;
      justify-content: center !important;
      display: flex !important;
      padding: 0;
      padding-top: 0 !important;
      padding-bottom: 1rem !important;
    }
  }

  .verde {
    color: rgb(5, 194, 5);
    font-weight: 600;
    font-size: larger;
  }
}

.whatsapp-content {
  display: flex;
  align-items: center;
  gap: 8px;
}


.whatsapp {
  position: fixed;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 0px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  z-index: 100;
  -webkit-box-shadow: 0px 0px 42px 23px rgba(0, 0, 0, 0.32);
  -moz-box-shadow: 0px 0px 42px 23px rgba(0, 0, 0, 0.32);
  box-shadow: 0px 0px 42px 23px rgba(0, 0, 0, 0.32);
  transition: transform 0.2s ease;
}

.whatsapp-icon {
  margin-top: 0px;
}